<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Requisition </h1>
                </div>
                <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
                </div>
              </div>
              <div class="col-md-12 ml-auto mr-auto">
                <v-text-field v-model="searchPersonnel"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details></v-text-field>
              </div>
              <v-data-table :loading="loadingLogs"
                            loading-text="Loading data. Please wait...."
                            :headers='headers'
                            :items="requisitions"
                             :search="searchPersonnel">
                <template v-slot:[`item.dateNeeded`]="{ item }">
                  {{item.dateNeeded | formatDate}}
                </template>
                 <template v-slot:[`item.skillLevel`]="{ item }">
                    <span v-if="item.skillLevel == 0">HighLevel</span>
                    <span v-if ="item.skillLevel == 1">LowLevel</span>
                </template>
                 <template v-slot:[`item.requisitionProcess`]="{ item }">
                     <span v-if="item.requisitionProcess === 0">Internal</span>
                    <span v-if ="item.requisitionProcess === 1">External</span>
                    <span v-if ="item.requisitionProcess === 2">Renewal</span>
                </template>
                 <template v-slot:[`item.requisitionType`]="{ item }">
                     <span v-if="item.requisitionType === 0">Addition</span>
                    <span v-if ="item.requisitionType === 1">Replacement</span>
                    <span v-if ="item.requisitionType === 2">Renewal</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                     <span v-if="item.status === 0">Pending</span>
                    <span v-if ="item.status === 1">Approved</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="view(item)" class="mr-2" />
                  </div>
                </template>
              </v-data-table>
              <!-- View modal  -->
              <Dialog ref="requisitionViewModal" :title="'Requisition View Mode'" :width="700">
                <v-row v-if="requisit.length !==0">
                    <div class="row text-left">
                    <!-- <v-col cols="12" class="pl-5 pt-5" style="text-align: justify"> -->
                        <div class="text-caption col-md-6">Job Title: </div>
                            <div class="col-md-6"><b>{{requisit.jobTitle.name}}</b></div>

                    <div class="text-caption col-md-6">Department: </div>
                        <div class="col-md-6"><b> {{requisit.department.name}}</b></div>
                    <div class="text-caption col-md-6">Brief Job Description: </div>
                        <div class="col-md-6"><b> {{requisit.briefJobDescription}}</b></div>
                    <div class="text-caption col-md-6">Business Justification: </div>
                        <div class="col-md-6"><b> {{requisit.businessJustification}}</b></div>
                    <div class="text-caption col-md-6">Cost Centre: </div>
                        <div class="col-md-6"><b> {{requisit.costCentre}}</b></div>

                    <div class="text-caption col-md-6">Requisition Process: </div>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 0"> <b>Internal</b></span>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 1"> <b>External</b> </span>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 2"> <b>Renewal</b></span>

                    <div class="text-caption col-md-6" >Requisition Type: </div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 0"> <b>Addition</b></div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 1"> <b>Replacement </b></div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 2"> <b>Renewal</b></div>

                    <div class="text-caption col-md-6">Skill Level: </div>
                        <div class="col-md-6"  v-if="requisit.skillLevel == 0"> <b>High Level </b></div>
                        <div class="col-md-6" v-if="requisit.skillLevel == 1"> <b>Low Level </b></div>

                    <div class="text-caption col-md-6">Education: </div>
                        <div class="col-md-6"> <b>{{requisit.education}}</b></div>
                    <div class="text-caption col-md-6">Experience: </div>
                        <div class="col-md-6"> <b>{{requisit.experience}}</b></div>
                    <div class="text-caption col-md-6">Date Needed: </div>
                        <div class="col-md-6"><b> {{requisit.dateNeeded | formatDate}}</b></div>
                    <div class="text-caption col-md-6">Status: </div>
                        <div class="col-md-6"  v-if="requisit.status == 0"> Pending </div>
                        <div class="col-md-6"  v-if="requisit.status == 1"> Approved </div>

                  <!-- </v-col> -->
                    </div>
                </v-row>
                   <v-divider></v-divider>
                <template v-slot:footer>
                  <!-- <Button :btnType="'Close'" :label="'Cancel'" @onClick="closeallowanceViewModal" /> -->
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { FETCH_REQUISITION } from '@/store/action-type'
import { employeeRequisition } from '@/services'
// import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
export default {
  components: {
    Button,
    Dialog
    // PersonnelSearch
  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      searchPersonnel: '',
      valid: false,
      loadingLogs: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      tab: null,
      alertType: '',
      requisit: [],
      headers: [
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Requisition Type',
          value: 'requisitionType'
        },
        {
          text: 'Requisition Process',
          value: 'requisitionProcess'
        },
        {
          text: 'Date Needed',
          value: 'dateNeeded',
          align: 'center'
        },
        {
          text: 'Skill Level',
          value: 'skillLevel'
        },
        {
          text: 'Next Approver',
          value: 'nextApprover'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    requisitions () {
      return this.$store.getters.requisitions
    },
    currentUser () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    Add () {
      this.$router.push('/requisition/add')
    },
    view (item) {
      this.loadingLogs = true
      employeeRequisition.getRequisitionById(item.id).then(({ data }) => {
        console.log(data)
        this.requisit = data
      }).catch(() => {
      }).finally(() => {
        this.loadingLogs = false
      })

      this.$refs.requisitionViewModal.openModal()
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    fetchRequisition () {
      this.loadingLogs = true
      this.$store
        .dispatch(FETCH_REQUISITION)
        .then(() => {
          this.loadingLogs = false
        })
        .catch((error) => {
          if (error) {
            this.loadingLogs = false
            this.showAlertMessage('Unable to display Requisition', 'error')
          }
        })
    }

  },
  mounted () {
    this.fetchRequisition()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
.approval-title{
      font-size: 14px;
    font-weight: bold;
    margin-bottom: 40px;
}
.v-tab--active{
   background-color: #faf8f8 !important;
   color: #0b2d71 !important
}
.v-tabs-slider-wrapper{
  background-color: #0b2d71 !important
}
</style>
